<template>
  <div v-if="!isFetching">
    <div style="padding-top:15px;display:inline-block;padding-left:10px;">
      <span>
        <a id="link_voltar" href="javascript:history.go(-1)">
          &lt; &lt; Voltar</a
        >
        <h5 class="title" style="font-weight:350;padding-bottom: 10px;">
          Servidor -
          <small>id: {{ id }}</small>
        </h5></span
      >
    </div>
    <div class="switch-div">
      <md-switch v-model="editionMode">Modo Edição </md-switch>
    </div>
    <div class="md-layout">
      <div class="md-layout-item">
        <div style="padding-top:15px;display:inline-block;">
          <h4 class="title">Tabela de Histótico</h4>
          <h5 class="title">
            Aqui é possivel criar novos Históricos de alterações de Vínculos.
          </h5>
        </div>
        <div class="switch-div">
          <md-button
            v-show="showButton"
            class="md-raised md-success mt-4"
            @click="novoHistoricoVinculos()"
          >
            Novo Histótico
          </md-button>
        </div>
        <md-card>
          <md-card-content>
            <md-table
              :value="queriedData"
              :md-sort.sync="currentSort"
              :md-sort-order.sync="currentSortOrder"
              :md-sort-fn="customSort"
              class="paginated-table table-striped table-hover"
            >
              <md-table-toolbar>
                <md-field>
                  <label for="pages">Por pagina</label>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
                <span class="space-tool-bar"></span>
                <md-field>
                  <md-input
                    type="search"
                    class="mb-3"
                    clearable
                    style="width: 200px"
                    placeholder="Pesquisar"
                    v-model="searchQuery"
                  >
                  </md-input>
                  <md-icon>search</md-icon>
                </md-field>
                ( Pesquise por Siape ou Código Orgão ou Descrição Orgão ou Data
                Ingresso Orgão ou Data Saída Orgão)
              </md-table-toolbar>
              <br />
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Siape" md-sort-by="siapeServidor">{{
                  item.siapeServidor
                }}</md-table-cell>
                <md-table-cell md-label="Código Orgão" md-sort-by="codOrgao">{{
                  item.codOrgao
                }}</md-table-cell>

                <md-table-cell
                  md-label="Descrição Orgão"
                  md-sort-by="orgDescricao"
                  >{{ item.orgDescricao }}</md-table-cell
                >

                <md-table-cell md-label="Data Ingresso Orgão">{{
                  item.dataIngressoOrgao
                }}</md-table-cell>

                <md-table-cell md-label="Data Saida Orgão">{{
                  item.dataSaidaOrgao
                }}</md-table-cell>

                <md-table-cell
                  md-label="Unidade Exercicio"
                  md-sort-by="uorgExercicio"
                  >{{ item.uorgExercicio }}</md-table-cell
                >

                <md-table-cell
                  md-label="Unidade Exercicio Descrição"
                  md-sort-by="nomeUnidadeExercicio"
                  >{{ item.nomeUnidadeExercicio }}</md-table-cell
                >

                <md-table-cell md-label="Unidade Pagadora" md-sort-by="upag">{{
                  item.upag
                }}</md-table-cell>

                <md-table-cell
                  md-label="Unidade Pagadora Descrição"
                  md-sort-by="upagDescricao"
                  >{{ item.upagDescricao }}</md-table-cell
                >

                <md-table-cell md-label="Ações" v-if="totalRegistros > 0">
                  <md-button
                    class="md-just-icon md-success md-simple"
                    @click.native="handleEdit(item)"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-tooltip md-direction="top">Editar</md-tooltip>
                    <md-icon>edit</md-icon>
                  </md-button>
                  <md-button
                    class="md-just-icon md-danger md-simple"
                    @click.native="handleDelete(item)"
                    :disabled="checkEditionDisabled()"
                  >
                    <md-tooltip md-direction="top">Excluir</md-tooltip>
                    <md-icon>delete</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Mostrando {{ from + 1 }} até {{ to }} de {{ total }} registros
              </p>
            </div>
            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            >
            </pagination>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <template>
      <modal v-if="classicModal" @close="classicModalHide">
        <template slot="header">
          <h4 class="modal-title">{{ modalTitle }}</h4>
          <md-button
            class="md-simple md-just-icon md-round modal-default-button"
            @click="classicModalHide"
          >
            <md-icon>clear</md-icon>
          </md-button>
        </template>

        <template slot="body">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <form
              @submit.prevent="
                formCriar === true
                  ? handleSubmit(submit)
                  : handleSubmit(editRow)
              "
            >
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.siapeServidor"
                    :rules="{
                      regex: /^\d{7}$/,
                      min: 7,
                      max: 7,
                      required: true,
                      numeric: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Siape Servidor</label>
                      <md-input
                        v-model="form.siapeServidor"
                        type="text"
                        :maxlength="7"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ "Siape deve ter 7 digitos." }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.codOrgao"
                    :rules="{
                      regex: /^\d{5}$/,
                      min: 5,
                      max: 5,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Código Orgão</label>
                      <md-input
                        v-model="form.codOrgao"
                        type="text"
                        :maxlength="5"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ "Código Orgão deve ter 5 digitos." }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.orgDescricao"
                    :rules="{
                      alpha_spaces: true,
                      min: 3,
                      max: 100,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Orgão Descrição</label>
                      <md-input
                        v-model="form.orgDescricao"
                        type="text"
                        :maxlength="100"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider
                    name="form.dataIngressoOrgao"
                    :rules="{
                      regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                      min: 10,
                      max: 10,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Data Ingresso Orgão</label>
                      <md-input
                        v-model="form.dataIngressoOrgao"
                        type="text"
                        :maxlength="10"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ "Data deve ter formato DD/MM/AAAA" }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-50">
                  <ValidationProvider
                    name="form.dataSaidaOrgao"
                    :rules="{
                      regex: /([0-2]\d{1}|3[0-1])\/(0\d{1}|1[0-2])\/(19|20)(\d{2})/,
                      min: 10,
                      max: 10,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Data Saida Orgão</label>
                      <md-input
                        v-model="form.dataSaidaOrgao"
                        type="text"
                        :maxlength="10"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ "Data deve ter formato DD/MM/AAAA" }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.uorgExercicio"
                    :rules="{
                      regex: /^\d{9}$/,
                      min: 9,
                      max: 9,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Unidade Exercício</label>
                      <md-input
                        v-model="form.uorgExercicio"
                        type="text"
                        :maxlength="9"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ "Unidade Exercício deve ter 9 digitos." }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.nomeUnidadeExercicio"
                    :rules="{
                      min: 3,
                      max: 100,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Unidade Exercício Descrição</label>
                      <md-input
                        v-model="form.nomeUnidadeExercicio"
                        type="text"
                        :maxlength="100"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.upag"
                    :rules="{
                      regex: /^\d{9}$/,
                      min: 9,
                      max: 9,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Unidade Pagadora</label>
                      <md-input
                        v-model="form.upag"
                        type="text"
                        :maxlength="9"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ "Unidade Pagadora deve ter 9 digitos." }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <ValidationProvider
                    name="form.upagDescricao"
                    :rules="{
                      min: 3,
                      max: 100,
                      required: true
                    }"
                    v-slot="{ passed, failed, errors }"
                  >
                    <md-field
                      :class="[{ 'md-error': failed }, { 'md-valid': passed }]"
                    >
                      <label>Unidade Pagadora Descrição</label>
                      <md-input
                        v-model="form.upagDescricao"
                        type="text"
                        :maxlength="100"
                        :disabled="checkEditionDisabled()"
                      ></md-input>
                    </md-field>
                    <span
                      id="error"
                      class="block text-red-600 text-xs absolute bottom-0 left-0"
                      v-if="errors[0]"
                      >{{ errors[0] }}
                    </span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="md-layout">
                <div class="md-layout-item md-size-100 text-right">
                  <md-button type="submit" class="md-raised md-success mt-4">
                    {{ buttonModal }}
                  </md-button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
        <template slot="footer"> </template>
      </modal>
    </template>
  </div>
</template>
<script>
import { extend, localize } from "vee-validate";
import pt_BR from "vee-validate/dist/locale/pt_BR.json";
import { store } from "../../../store";
import { mapState, mapActions, mapGetters } from "vuex";
import { Modal } from "@/components";
import * as rules from "vee-validate/dist/rules";
import Vue from "vue";
import { dataMask } from "@/pages/Dashboard/js/dataMask";
import { Pagination } from "@/components";
import Swal from "sweetalert2";
import Fuse from "fuse.js";

var pt_br = pt_BR;
var names = {};
Vue.set(pt_br, "names", names);
for (let key in pt_br.messages) {
  extend(key, rules[key]);
}
localize("pt_BR", pt_br);

export default {
  props: {
    id: {
      type: String
    },
    isEditionIn: {
      type: Boolean
    }
  },
  components: {
    Pagination,
    Modal
  },
  computed: {
    ...mapState("auth", ["user_profile"]),
    ...mapGetters("papel", {
      papelSelected: "papelSelectedState"
    }),
    ...mapGetters("servidor_publico", {
      servidorPublicoSelected: "servidorPublicoState"
    }),
    user_profile_state: state => state.user_profile,
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    reload: function() {
      this.getHistoricoVinculos();
      return this.tableData;
    }
  },
  data() {
    return {
      mensagem: "",
      idServidorSelected: this.id,
      classicModal: false,
      formCriar: true,
      buttonModal: "criar",
      modalTitle: "Novo Histórico",
      idQuota: null,
      form: {
        idHistVinculos: "",
        servidorPublico: "",
        codOrgao: "",
        dataIngressoOrgao: "",
        dataSaidaOrgao: "",
        nomeUnidadeExercicio: "",
        orgDescricao: "",
        siapeServidor: "",
        uorgExercicio: "",
        upag: "",
        upagDescricao: ""
      },
      editionMode: false,
      showButton: false,
      isDisabledEdition: true,
      isFetching: true,
      papel: "",
      currentSort: "codOrgao",
      currentSortOrder: "desc",
      totalRegistros: 0,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 20, 50, 100],
        total: 0
      },
      footerTable: [
        "Siape",
        "Código Orgão",
        "Descrição Orgão",
        "Data Ingresso Orgão",
        "Data Saida Orgão",
        "Unidade Exercicio",
        "Unidade Exercicio Descrição",
        "Unidade Pagadora",
        "Unidade Pagadora Descrição",
        "Ações"
      ],
      propsToSearch: [
        "siapeServidor",
        "codOrgao",
        "orgDescricao",
        "dataIngressoOrgao",
        "dataSaidaOrgao"
      ],
      searchQuery: "",
      tableData: [],
      searchedData: [],
      fuseSearch: null,
      componentKeyToRerender: 0
    };
  },
  beforeMount() {
    this.getPapel();
    this.getHistoricoVinculos();
    this.editionMode = this.isEditionIn;
  },
  methods: {
    ...mapActions("historico_vinculos", [
      "ActionSetHISTORICO_VINCULOS",
      "ActionPOSTHistoricoVinculos",
      "ActionDELETEHistoricoVinculos",
      "ActionPUTHistoricoVinculos",
      "ActionGetHistoricoVinculosFromServ"
    ]),
    emitToParent(value) {
      this.$emit("edition", value);
    },
    classicModalHide: function() {
      this.classicModal = false;
    },
    delay(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    getPapel() {
      this.papel = this.user_profile_state.papel;
    },
    forceRerender() {
      this.componentKeyToRerender += 1;
    },
    novoHistoricoVinculos() {
      this.classicModal = true;
      this.modalTitle = "Novo Historico";
      this.buttonModal = "criar";
      this.formCriar = true;
      this.form.servidorPublico = this.idServidorSelected;
    },
    async getHistoricoVinculos() {
      try {
        if (
          typeof this.servidorPublicoSelected !== "undefined" &&
          this.servidorPublicoSelected !== "" &&
          this.servidorPublicoSelected !== null &&
          this.servidorPublicoSelected.idServidor
        ) {
          let papel = this.papel;
          let id = this.id;
          let response = await this.ActionGetHistoricoVinculosFromServ({
            id,
            papel
          }).then(res => {
            if (res.body.mensagem) {
              if (res.body.mensagem.includes("não")) {
                this.totalRegistros = 0;
                this.tableData = [
                  {
                    codOrgao: "",
                    dataIngressoOrgao: "",
                    dataSaidaOrgao: "",
                    idHistVinculos: "",
                    nomeUnidadeExercicio: "",
                    orgDescricao: "",
                    siapeServidor: "",
                    uorgExercicio: "",
                    upag: "",
                    upagDescricao: ""
                  }
                ];
                this.isFetching = false;
              }
            } else {
              this.tableData = res.body;
              this.totalRegistros = this.tableData.length;
              this.isFetching = false;
            }
          });
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          if (
            typeof a[sortBy] !== "undefined" &&
            typeof b[sortBy] !== "undefined"
          ) {
            return a[sortBy].toString().localeCompare(b[sortBy].toString());
          }
        }
        if (
          typeof a[sortBy] !== "undefined" &&
          typeof b[sortBy] !== "undefined"
        ) {
          return b[sortBy].toString().localeCompare(a[sortBy].toString());
        }
      });
    },
    async submit() {
      try {
        let papel = this.papel;
        let form = this.form;
        await this.ActionPOSTHistoricoVinculos({
          papel,
          form
        }).then(res => {
          if (res.status === 200) {
            if (res.body.mensagem) {
              this.mensagem = res.body.mensagem;
              if (res.body.mensagem.includes("não")) {
                Swal.fire({
                  title: `${res.body.mensagem}`,
                  icon: "error",
                  text: `${this.mensagem}`,
                  customClass: {
                    confirmButton: "md-button md-raised md-danger btn-fill"
                  },
                  buttonsStyling: false
                });
              } else {
                this.classicModalHide();
                this.reload;
                Swal.fire({
                  //title: "Sucesso",
                  icon: "success",
                  text: "Histórico Criado",
                  customClass: {
                    confirmButton: "md-button md-success btn-fill"
                  },
                  buttonsStyling: false
                });
                this.form = {
                  idHistVinculos: "",
                  servidorPublico: this.idServidorSelected,
                  codOrgao: "",
                  dataIngressoOrgao: "",
                  dataSaidaOrgao: "",
                  nomeUnidadeExercicio: "",
                  orgDescricao: "",
                  siapeServidor: "",
                  uorgExercicio: "",
                  upag: "",
                  upagDescricao: ""
                };
              }
            }
          }
        });
      } catch (err) {
        this.classicModalHide();
        this.handleError(err);
      }
    },
    handleDelete(item) {
      Swal.fire({
        title: "Você tem certeza ?",
        text: `Este Histórico será excluido do Servidor ! Isto será irreversível !`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim, excluir isto!",
        cancelButtonText: "CANCELAR",
        customClass: {
          confirmButton: "md-button md-success md-raised mt-4",
          cancelButton: "md-button md-danger md-raised mt-4"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },
    async deleteRow(item) {
      try {
        let id = item.idHistVinculos;
        let papel = this.papel;
        let response = await this.ActionDELETEHistoricoVinculos({
          id,
          papel
        });
        if (response.status === 200) {
          let indexToDelete = this.tableData.findIndex(
            tableRow => tableRow.idHistVinculos === item.idHistVinculos
          );
          if (indexToDelete >= 0) {
            this.tableData.splice(indexToDelete, 1);
          }
          this.mensagem = response.body.mensagem;
          if (this.mensagem.includes("não")) {
            Swal.fire({
              title: `Histórico: ${item.idHistVinculos} Não Excluido ! - Servidor : ${this.idServidorSelected} `,
              icon: "error",
              text: this.mensagem,
              text: `${this.mensagem}`,
              customClass: {
                confirmButton: "md-button md-raised md-danger btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            Swal.fire({
              icon: "success",
              title: `Histórico: Excluido!`,
              text: `${this.mensagem}` + "\nid:" + `${item.idHistVinculos} `,
              customClass: {
                confirmButton: "md-button md-raised mt-4 md-success btn-fill"
              },
              buttonsStyling: false
            });
          }
          this.searchQuery = "";
          this.reload;
        }
      } catch (err) {
        console.log(err);
        this.handleError(err);
      }
    },
    checkEditionDisabled() {
      if (this.isDisabledEdition === true) {
        return "disabled";
      } else {
        return null;
      }
    },
    handleEdit(item) {
      this.formCriar = false;
      this.buttonModal = "Atualizar";
      this.modalTitle = "Editar Historico";
      //this.form.idHistVinculos = item.idHistVinculos;
      this.classicModal = true;
      this.form = { ...item };
      this.form.servidorPublico = this.idServidorSelected;
    },
    async editRow() {
      try {
        let id = this.form.idHistVinculos;
        let form = this.form;
        let papel = this.papel;
        let response = await this.ActionPUTHistoricoVinculos({
          id,
          papel,
          form
        });
        if (response.status === 200) {
          if (response.body.mensagem.includes("atualizado com sucesso")) {
            this.classicModalHide();
            this.reload;
            Swal.fire({
              icon: "success",
              //title: "Atualizado!",
              text: `${response.body.mensagem}`,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false
            });
          } else {
            this.classicModalHide();
            this.reload;
            Swal.fire({
              icon: "warning",
              //title: "Atualizado!",
              text: `${response.body.mensagem}`,
              customClass: {
                confirmButton: "md-button md-success btn-fill"
              },
              buttonsStyling: false
            });
          }
        }
      } catch (err) {
        this.classicModalHide();
        this.handleError(err);
      }
    },
    handleError(err) {
      console.log(err);
      if (
        err === 400 ||
        err === 500 ||
        err === 404 ||
        err.status === 400 ||
        err.status === 500 ||
        err.status === 404
      ) {
        this.mensagem = "Aplicação temporariamente indisponível.";
      } else if (err.status === 401 || err.data === "Unauthorized") {
        this.mensagem = "Operação não Autorizada";
      } else {
        if (err.body) {
          this.mensagem = err.body.mensagem;
        } else if (err.status === 0) {
          this.mensagem =
            "Verifique sua conexão com a internet ou aguarde restabelecer conexões.";
        } else if (err.status === 409) {
          this.mensagem = err.body.mensagem;
        } else {
          this.mensagem =
            "Operação temporariamente indisponível tente mais tarde.";
        }
      }
      Swal.fire({
        title: "Erro!",
        icon: "error",
        text: this.mensagem,
        customClass: {
          confirmButton: "md-button md-danger btn-fill"
        },
        buttonsStyling: false
      });
    }
  },
  watch: {
    "form.dataIngressoOrgao": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dataIngressoOrgao = data;
    },
    "form.dataSaidaOrgao": function(val, oldVal) {
      let data = dataMask(val.toString());
      this.form.dataIngressoOrgao = data;
    },
    editionMode: function(val, oldVal) {
      let edition = val;
      if (edition) {
        this.isDisabledEdition = false;
        this.showButton = true;
        this.showButtonAtualizar = true;
        ///checkEditionDisabled();
      } else {
        this.isDisabledEdition = true;
        this.showButton = false;
        this.showButtonAtualizar = false;
      }
      this.emitToParent(edition);
    },
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        this.fuseSearch = new Fuse(result, {
          keys: this.propsToSearch,
          threshold: 0.1
        });
        result = this.fuseSearch.search(this.searchQuery);
        this.fuseSearch = null;
      }
      this.searchedData = result;
    }
  }
};
</script>

<style lang="css" scoped>
::v-deep .paginated-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: 400;
}

.footer-table .md-table-head-label {
  font-size: 0.8rem;
  font-weight: bold;
}
.md-table-cell {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.74rem;
}
#error {
  color: red;
  font-size: 0.75rem;
  margin-top: 0;
  transition: all 0.2s ease-in-out;
}

.md-card .md-card-actions {
  border: none;
  margin-left: 20px;
  margin-right: 20px;
}
.space-tool-bar {
  width: 50px;
  height: 50px;
}

.md-button.md-info.md-simple,
.md-button.md-info.md-simple:hover,
.md-button.md-info.md-simple:focus,
.md-button.md-info.md-simple:active,
.md-button.md-info.md-simple.active,
.md-button.md-info.md-simple:active:focus,
.md-button.md-info.md-simple:active:hover,
.md-button.md-info.md-simple.active:focus,
.md-button.md-info.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}

.md-button.md-success.md-simple,
.md-button.md-success.md-simple:hover,
.md-button.md-success.md-simple:focus,
.md-button.md-success.md-simple:active,
.md-button.md-success.md-simple.active,
.md-button.md-success.md-simple:active:focus,
.md-button.md-success.md-simple:active:hover,
.md-button.md-success.md-simple.active:focus,
.md-button.md-success.md-simple.active:hover {
  margin-right: 5px;
  margin-left: 5px;
}
.paginated-table .md-table-cell-container .md-button .md-button-content i {
  font-size: 24px !important;
}

.size35 i {
  font-size: 35px !important;
}

::v-deep .md-toolbar-section-start {
  padding-bottom: 20px;
}

::v-deep .md-card .md-card-content {
  padding: 2px 20px;
}

::v-deep .md-switch-label {
  font-weight: bold;
  color: rgba(14, 14, 14, 0.52);
}

::v-deep .md-switch.md-checked .md-switch-container {
  background-color: #49a54d !important;
}

::v-deep .md-switch.md-checked .md-switch-thumb {
  border: 1px solid #49a54d;
}

.switch-div {
  float: right;
}

#link_voltar {
  color: green;
  font-weight: bold;
  text-decoration: none;
  text-transform: uppercase;
}

a:hover,
a:focus {
  color: green !important;
  text-decoration: none !important;
}

::v-deep .modal-container {
  max-width: 400px;
}

.paginated-table table > tbody > tr > td {
  max-width: 150px;
  width: 0px;
}

::v-deep .modal-body.text-center {
  height: 380px;
  overflow-y: auto;
}
</style>
